import { Icon } from '@iconify/react/dist/iconify.js'
import React, { useEffect, useState } from 'react'
import TestSeriesBox from './TestSeriesBox'
import img from "../../src/assert/istockphoto-1153425570-612x612.jpg"
import img2 from "../assert/images.png"
import token from '../Config/Token'
import Backend_Url from '../Config/BackendUrl'
import { json } from 'react-router-dom'


const Renderclasses = () => {
    const [allcontent, setallcontent] = useState([])
    const data1 = {
        testseries: "ONLINE TEST SERIES",
        div1mainheading: "Major Test Series",
        div1subhead: "All india Test Series",
        div2data: [
            "14+ Full Syllabus Tests",
            "Detailed Solutions to All Question",
            "Exclusive Shreeji Question Bank",
        ],
        div3falseprice: "5999",
        div3trueprice: "4499",
        knowmoreDirect: "/major-online-test"
    }

    const Leadertestseries = {
        testseries: "ONLINE TEST SERIES",
        div1mainheading: "Leader Test Series",
        div1subhead: "All india Test Series",
        div2data: [
            "20+ (Part+Full) Syllabus Tests",
            "Detailed Solutions to All Question",
            "Exclusive Shreeji Question Bank",
        ],
        div3falseprice: "8500",
        div3trueprice: "5999",
        knowmoreDirect: ""
    }

    const LeaderJoint = {
        testseries: "ONLINE TEST SERIES",
        div1mainheading: "Leader Joint Series",
        div1subhead: "Leader Test Series + Study Material",
        div2data: [
            "20+ (Part+Full) Syllabus Tests",
            "Study Material Delivered At Home",
            "Exclusive Shreeji Question Bank",
        ],
        div3falseprice: "1599",
        div3trueprice: "11,999",
        knowmoreDirect: ""
    }

    // const Recorded = {
    //     testseries: "Recorded",
    //     div1mainheading: "Leader Recorded Lecture",
    //     div1subhead: "Video Lecture + Test Series",
    //     div2data: [
    //         "Latest Recordings Coverring Full Syllabus",
    //         "20+ All India Tests",
    //         "Exclusive Shreeji Question Bank",
    //     ],
    //     div3falseprice: "20999",
    //     div3trueprice: "14,999",
    //     knowmoreDirect: ""
    // }

    const [data, setdata] = useState([])
    const fetchdata = async () => {
        try {
            debugger
            const response = await fetch(`${Backend_Url}jsonroute/get`, {
                method: 'GET', headers: {
                    'authorization': 'Bearer ' + token
                }
            })
            const data = await response.json()
            console.log("json", JSON.parse(data.json[0].JsonObject));
            // console.log("jsonconvert",);
            data.json.map((item) => {
                const newItem = JSON.parse(item.JsonObject);

                // Use setAllContent to update state with unique items only
                setallcontent((prev) => {
                    // Check if the item already exists in the previous state
                    const isDuplicate = prev.some(existingItem =>
                        JSON.stringify(existingItem) === JSON.stringify(newItem)
                    );

                    // If it's not a duplicate, add it to the previous state
                    return isDuplicate ? prev : [...prev, newItem];
                });
            });
            // setallcontent(JSON.parse(data.json[0].JsonObject))
            // setallcontent(data.json)
        } catch (error) {
            console.error('Error:', error)
        }
    }

    useEffect(() => {
        fetchdata()
    }, []);

    useEffect(() => {
        console.log(allcontent)
    }, [allcontent]);

    // function convertStringToObject(str = "") {
    //     // Remove unwanted spaces, tabs, or newline characters
    //     let cleanStr = str.trim("");
    //     console.log("Cleaned String:", cleanStr);

    //     // Attempt to parse the cleaned string
    //     try {
    //         let jsonObject = JSON.parse(str);
    //         return jsonObject;
    //     } catch (error) {
    //         console.error("Invalid JSON string:", error);
    //         return null;
    //     }
    // }

    // const object = convertStringToObject(data[0]?.Details)

    // console.log(object);


    return (

        <>
            {/* ----------------------------Online test series-------------------- */}
            <div className={`online-test`}>
                {/* heading */}
                <div>
                    <h1>
                        <p>Online Test Series Packages</p>
                        <div>
                            <img src={img} />
                        </div>
                    </h1>
                    {/* box */}
                    <ul className='online-box'>
                        {
                            allcontent.map((data) => {
                                return (
                                    <>
                                        <TestSeriesBox data={data} />
                                    </>
                                )
                            })
                        }
                        {/* <TestSeriesBox data={data1} />
                        <TestSeriesBox data={Leadertestseries} /> */}
                        {/* <TestSeriesBox data={Leadertestseries} /> */}
                    </ul>
                </div>




            </div>


        </>
    )
}

export default Renderclasses





//     < div >
//     <h1>
//         <p>Recorded Lectures Program</p>
//         <div>
//             <img src={img2} />
//         </div>
//     </h1>
// {/* box */ }
// <ul className='online-box'>
//     {/* <TestSeriesBox data={Recorded} /> */}

// </ul>
// </div >